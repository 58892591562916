import { render, staticRenderFns } from "./public.vue?vue&type=template&id=6b7f17c9"
import script from "./public.vue?vue&type=script&lang=js"
export * from "./public.vue?vue&type=script&lang=js"
import style0 from "./public.vue?vue&type=style&index=0&id=6b7f17c9&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomerAffiliateRoleModal: require('/root/dashboard/components/customerAffiliateRoleModal/CustomerAffiliateRoleModal.vue').default,GlobalPopups: require('/root/dashboard/components/globalPopups/GlobalPopups.vue').default})
