import { render, staticRenderFns } from "./ForceEmailConfirmationPopup.vue?vue&type=template&id=273e3dc4&scoped=true"
import script from "./ForceEmailConfirmationPopup.vue?vue&type=script&lang=js"
export * from "./ForceEmailConfirmationPopup.vue?vue&type=script&lang=js"
import style1 from "./ForceEmailConfirmationPopup.vue?vue&type=style&index=1&id=273e3dc4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273e3dc4",
  null
  
)

export default component.exports