import { render, staticRenderFns } from "./GlobalPopups.vue?vue&type=template&id=07a7c5a2&scoped=true"
import script from "./GlobalPopups.vue?vue&type=script&lang=js"
export * from "./GlobalPopups.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a7c5a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CashFTPurchaseCardModal: require('/root/dashboard/components/cashFT/cashFTPurchaseCardModal/CashFTPurchaseCardModal.vue').default})
