import { render, staticRenderFns } from "./ProductOwnerSelection.vue?vue&type=template&id=5a72160d&scoped=true"
import script from "./ProductOwnerSelection.vue?vue&type=script&lang=ts"
export * from "./ProductOwnerSelection.vue?vue&type=script&lang=ts"
import style0 from "./ProductOwnerSelection.scss?vue&type=style&index=0&id=5a72160d&prod&scoped=true&lang=scss&external"
import style1 from "./ProductOwnerSelection.vue?vue&type=style&index=1&id=5a72160d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a72160d",
  null
  
)

export default component.exports